import { type SettingsState, type UserEvent } from '../../../types';
import { updateState } from '../../models';
import { createToast } from '../../toasts.platform';
import { StateUpdateOptionsWithoutEventName } from '../../types';

export const updateDefaultPageSetting = async (
  value: SettingsState['defaultPage'],
  options: StateUpdateOptionsWithoutEventName & {
    showToast?: boolean;
  },
): Promise<void> => {
  const updateResult = await updateState(
    (state) => {
      state.persistent.settings.defaultPage = value;
    },
    { ...options, eventName: 'default-page-setting-updated' },
  );

  if (options.showToast) {
    createToast({
      content: 'Default page updated',
      category: 'success',
      undoableUserEventId: (updateResult.userEvent as UserEvent).id,
    });
  }
};

export const updateExportClipboardTemplate = async (tpl: string): Promise<void> => {
  await updateState(
    (state) => {
      state.persistent.settings.exportClipboardTemplate = tpl;
    },
    {
      userInteraction: 'click',
      eventName: 'export-clipboard-template-updated',
      shouldCreateUserEvent: true,
      isUndoable: false,
    },
  );
};

export async function saveRedactedOpenAIApiKey(key: string) {
  if (key.length === 0) {
    return;
  }

  const mask = (key: string) => key.slice(-4).padStart(key.length, '•');
  await updateState(
    (state) => {
      if (!state.persistent.settings.openai) {
        state.persistent.settings.openai = { apiKey: undefined };
      }
      state.persistent.settings.openai.apiKey = mask(key);
    },
    {
      eventName: 'update-openai-api-key-settings',
      userInteraction: 'unknown',
    },
  );
}

export async function removeRedactedOpenAIApiKey() {
  await updateState(
    (state) => {
      if (!state.persistent.settings.openai) {
        state.persistent.settings.openai = { apiKey: undefined };
        return;
      }
      state.persistent.settings.openai.apiKey = undefined;
    },
    {
      eventName: 'update-openai-api-key-settings',
      userInteraction: 'unknown',
    },
  );
}
